import { DAY } from "./timeConstants";


const formatDateToYMD = (d: Date) => d.toISOString().slice(0,10);

export function domainReportFileName() {
  const now = new Date();
  const sixtyDaysAgo = new Date(now.getTime() - (60 * DAY));
  const reportName = `scraperapi_domainreport_${ formatDateToYMD(sixtyDaysAgo) }_${ formatDateToYMD(now) }.csv`;
  return reportName;
}
