import { AdditionalOptionsListbox } from "./AdditionalOptionsListBox";
import type { AsyncApiParams, CollectorConfig } from "providers/HostedScrapingProvider/types";

type OutputFormatDropdownProps = {
  collectorConfig: CollectorConfig;
  updateApiParams: (key: string) => (newValue: string | boolean | number | undefined, apiParamsToChange?: AsyncApiParams) => AsyncApiParams;
};
const OutputFormatDropdown = ({ collectorConfig, updateApiParams}: OutputFormatDropdownProps) => {

  const options = [
    { text: 'JSON', value: 'json' },
    { text: 'CSV', value: 'csv' }
  ];  

  let value = undefined;
  if (collectorConfig.apiParams?.outputFormat === 'json' || collectorConfig.apiParams?.outputFormat === undefined) {
    value = 'json';
  } else if (collectorConfig.apiParams?.outputFormat === 'csv') {
    value = 'csv';
  }

  return <>
    <div className="mt-5">
      Output format
    </div>
    <AdditionalOptionsListbox value={ value } options={ options } 
      callback={(new_value) => {
      switch (new_value) {
        case 'json': {
            updateApiParams('outputFormat')(undefined);
            break;
          }
        case 'csv': {
            updateApiParams('outputFormat')('csv');
            break;
          }
      };
    }} buttonTestId="testApiPlaygroundOutputFormat" />
  </>
};
export default OutputFormatDropdown;

