import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Listbox } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";

import scraperApi from "api";
import { AsyncApiParams, hasEmptyInput, InputConnectorConfig, NewProjectConfig, OutputConfig, OutputFormat, ProjectConfig, WebhookOutputConfig } from "providers/HostedScrapingProvider/types";

import { ProjectDetailsHead } from 'components/hosted-scraping/ProjectDetailsHead';
import { ProjectSummarySidebar } from "components/hosted-scraping/project-summary/ProjectSummarySidebar";
import { RadioGroup, RadioGroupElement } from "components/RadioGroup";
import { Tooltip } from "components/Tooltip";
import { ListboxButton, listboxButtonClasses, ListboxElement, listboxOptionClasses, listboxOptionsClasses, OptionsTransition } from "components/Listbox";
import { ProjectBreadcrumbPage } from "components/ProjectBreadcrumbPage";
import Button from 'components/Button';
import { schedulingInterval, ScrapingIntervalSetting, validSchedulingIntervals } from 'components/hosted-scraping/showProjectConfig';
import { calculateNextRuns, intoCronExpression, isValidCron } from 'components/hosted-scraping/cronUtils';
import { InputComments } from 'components/hosted-scraping/edit-project-components/InputComments';
import { tooltips } from 'components/hosted-scraping/edit-project-components/tooltips';
import { UrlProjectAsyncApiParams, AmazonProjectAsyncApiParams, GoogleProjectAsyncApiParams, WalmartProjectAsyncApiParams, EbayProjectAsyncApiParams, RedfinProjectAsyncApiParams } from 'components/hosted-scraping/edit-project-components/editApiParams';
import { formatDate } from 'components/hosted-scraping/ProjectFormatDate';
import { useLocalStorage } from "hooks/useLocalStorage";
import { useDebouncer } from "hooks/useDebouncer";
import { useHostedScrapingProjects } from "providers/HostedScrapingProvider";
import { ConfigProblem, ConfigProblemWithMessage, validateProject } from "components/hostedScrapingValidators";
import { InputSettings } from "components/hosted-scraping/edit-project-components/InputSettings";
import { NotificationPreferences } from "components/hosted-scraping/edit-project-components/NotificationPrefrerences";
import CronExpression from "components/hosted-scraping/CronExpression";
import Toaster from "components/Toaster";
import { useSettingsFromApi } from "providers/ApiSettingsProvider";
import { SectionTitle } from "components/hosted-scraping/edit-project-components/SectionTitle";
import { Separator } from "components/hosted-scraping/edit-project-components/Separator";
import { inputTypeToTitleAndDescription } from "components/hosted-scraping/CollectorInputs";
import { Section } from "components/hosted-scraping/edit-project-components/Section";
import { SectionRightHandSide } from "components/hosted-scraping/edit-project-components/SectionRightHandSide";
import axios from "axios";
import lodash from "lodash";
import { HostedScraperReviewProjectModal } from "components/Modal/HostedScraperModals";
import { AdditionalOptionsListbox } from "components/hosted-scraping/edit-project-components/AdditionalOptionsListBox";
import { getInputSectionLabelsHS, isAmazonProject, isAsyncUrlsProject, isEbayProject, isGoogleProject, isRedfinProject, isSDEProject, isWalmartProject, projectTypeToTitleAndDescriptionAP } from "sdecontent";
import he from 'he';

type Mode = 'newProject' | 'editProject';

const formatCrontabGuruLink = (cronExpression: string | undefined): string => {
  if (cronExpression === undefined) {
    return 'https://crontab.guru';
  } else {
    return `https://crontab.guru/#${encodeURIComponent(cronExpression.replaceAll(' ', '_'))}`;
  }
}

const CronScheduling = ({cronExpression, callback}: {cronExpression: string, callback: (idx: number, newValue: string) => void}) => {
  const cronElements = cronExpression.split(' ');
  const { valid, message: errorMessage } = isValidCron(cronExpression);
  return (
    <div className="flex flex-col mt-4 p-2 bg-lightestGray-100 dark:bg-neutral-50 gap-4">
      <div className="flex flex-row flex-nowrap gap-4 py-4">
        {
          ['Minute', 'Hour', 'Day', 'Month', 'Weekday'].map((label, index) => {
            return (
              <div key={label} className="flex flex-col">
                <div className="text-sm text-gray dark:text-neutral-600">{label}</div>
                <input className="w-full border border-lightest shadow text-sm p-2" defaultValue={cronElements[index]} onChange={(e) => {
                  callback(index, e.target.value);
                }} />
              </div>
            );
          })
        }
      </div>
      { valid
        ? ( <div className="text-gray dark:text-neutral-600 text-sm mb-1">Resulting cron expression is <CronExpression cronExpression={cronExpression} /><br/>&nbsp;</div>)
        : ( <div className="text-red dark:text-error-600 text-sm mb-1">Cron expression is invalid <CronExpression cronExpression={cronExpression} /><br/>{errorMessage}</div>)
      }
      <div className="text-lightGray dark:text-neutral-500 text-sm text-right">Need help? Try <a href={formatCrontabGuruLink(cronExpression)} target="_blank" rel="noreferrer noopener"><span className="underline">crontab.guru</span></a></div>
    </div>
  );
};


const generateProjectName = (mode: Mode, existingProjects: ProjectConfig[]): string => {
  let i = 1;
  while (true) {
    const name = mode === "newProject" ? `New project ${i}` : `Project ${i}`;
    if (!existingProjects.find((p) => p.name === name)) {
      return name;
    }
    i++;
  }
};

// const SchedulerStartSwitch = ({enabled, callback}: {enabled: boolean, callback: (enabled: boolean) => void}) => {
//   return (<div className="flex flex-row flex-wrap items-center gap-2">
//     <Switch
//       checked={enabled}
//       onChange={callback}
//       className={`${
//         enabled ? 'bg-blue-600' : 'bg-gray-200 dark:bg-neutral-200'
//       } relative inline-flex h-6 w-11 items-center rounded-full z-0`}
//     >
//       <span
//         className={`${
//           enabled ? 'translate-x-6' : 'translate-x-1'
//         } inline-block h-4 w-4 transform rounded-full bg-white transition`}
//       />
//     </Switch>
//     <div className="text-base text-gray dark:text-neutral-600">Enable scheduling</div>
//   </div>);
// }


const toastDescription = (occasion: 'save' | 'update', scheduledAt: Date | string | undefined) => {
  const scheduledTime = new Date(scheduledAt!).getTime();
  const scheduledLater = scheduledAt && (scheduledTime - Date.now() >= 0);
  if (occasion === 'save' && Boolean(scheduledAt) && scheduledLater) {
    return 'Your first job will run on the scheduled time.'
  } else if (occasion === 'save' && Boolean(scheduledAt) && !scheduledLater) {
    return 'Your first job will start at any moment and you will be able to track the progress of your scraping project here.'
  } else if (occasion === 'save' && !Boolean(scheduledAt)) {
    return undefined;
  } else if (occasion === 'update' && Boolean(scheduledAt) && scheduledLater) {
    return 'Your next job will run on the scheduled time.'
  } else if (occasion === 'update' && Boolean(scheduledAt) && !scheduledLater) {
    return 'Your next job will start at any moment and you will be able to track the progress of your scraping project here.'
  } else if (occasion === 'update' && !Boolean(scheduledAt)) {
    return undefined;
  }
}

const scheduledDateOrNow = (proj: ProjectConfig | NewProjectConfig): Date => {
  // In reality proj.supposedToRunAt is sometimes a string
  return proj.supposedToRunAt ? new Date(proj.supposedToRunAt) : new Date()
}

export function NewProjectEditProject(
  {
    mode,
    project,
    cancelButtonVisible,
    backCallback,
  }: {
      mode: Mode,
      project: NewProjectConfig|ProjectConfig,
      cancelButtonVisible: boolean,
      backCallback: () => void,
    }) {
  const projectId = mode === 'editProject' ? (project as ProjectConfig ).id : undefined;

  const { projects, refreshInBackground, refreshProject } = useHostedScrapingProjects();
  const settingsFromApi = useSettingsFromApi();
  const [proj, setProj] = useState<NewProjectConfig|ProjectConfig>(project);
  const [detailsOpen, setDetailsOpen] = useLocalStorage<'up'|'down'>('editProjectShowDetails', 'down');

  const [localValidatedProblems, setLocalValidatedProblems] = useState<ConfigProblemWithMessage[]>([]);
  const [costCalculationProblems, setCostCalculationProblems] = useState<ConfigProblemWithMessage[]>([]);
  const [uploadFileProblems, setUploadFileProblems] = useState<ConfigProblemWithMessage[]>([]);

  const [cost, setCost] = useState<number|undefined>(undefined);
  const [costLoading, setCostLoading] = useState<boolean>(false);
  const defaultScrapingIntervalSetting = proj.enabled ? proj.scrapingInterval : 'scheduling_disabled';
  const [scrapingIntervalSetting, setScrapingIntervalSetting] = useState<ScrapingIntervalSetting>(defaultScrapingIntervalSetting);
  const [reviewProjectModalStuff, setReviewProjectModalStuff] = useState<{active: boolean, onSave: () => void}>({active: false, onSave: () => {}});


  // TODO: This should not be done with useEffect
  useEffect(() => {
    if (proj.scrapingInterval === undefined
      || proj.scrapingInterval === 'custom'
      || proj.scrapingInterval === 'cron') {
      return;
    }
    setProj({...proj, cron: intoCronExpression(scheduledDateOrNow(proj), proj.scrapingInterval)});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proj.scrapingInterval, proj.supposedToRunAt]);
  // Adding the proj to deps would cause an infinite loops because it calls setProj

  useEffect(() => {
    if (hasEmptyInput(proj.input)) {
      setCost(undefined);
      setCostCalculationProblems([]);
      return;
    }
    setCostLoading(true);
    const controller = new AbortController();
    scraperApi.hostedScraping.projectCost(proj, 'hosted', {signal: controller.signal})
      .then((response) => {
        setCost(response.cost);
        if (response.errorMessages && response.errorMessages.length > 0) {
          setCostCalculationProblems([ { problem: ConfigProblem.BackendCostCalculationError, multipleMessages: response.errorMessages } ]);
        } else {
          setCostCalculationProblems([]);
        }
      })
      .catch((err) => {if (!axios.isCancel(err)) {
        console.error(err);
        setCostCalculationProblems([ { problem: ConfigProblem.BackendCostCalculationError, message: 'Network error' } ]);
      }})
      .finally(() => setCostLoading(false));
    return () => { controller.abort(); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebouncer(proj.input), useDebouncer(proj.config.apiParams)]); // proj is deliberately not included!

  const navigate = useNavigate();

  const allProblems = [ ...localValidatedProblems, ...costCalculationProblems, ...uploadFileProblems ];

  const hasProblem = (problem: ConfigProblem): boolean => {
    return allProblems.some((p) => p.problem === problem)
  }

  const isInputUploaded = project.input.type === 'upload_csv_list' || project.input.type === 'upload_json_list';
  const hasUploadFileProblem = isInputUploaded && uploadFileProblems.length > 0;
  const canSaveProject = !hasUploadFileProblem && costCalculationProblems.length === 0;

  const nextRuns = useMemo(
    () => calculateNextRuns(proj.cron, proj.scrapingInterval !== 'cron' ? proj.supposedToRunAt || new Date() : undefined, 3), // If cron is selected, we should not consider proj.supposedToRunAt
    [proj.supposedToRunAt, proj.cron, proj.scrapingInterval]
  );

  const updateCron = (idx: number, newValue: string) => {
    const cronElements = proj.cron.split(' ');
    cronElements[idx] = newValue;
    const cronExpression = cronElements.join(' ');
    const firstRun = (() => {
      try {
        const firstCronRun = calculateNextRuns(cronExpression, new Date(), 1)[0];
        return firstCronRun;
      } catch (err) {
        console.error(err);
        return undefined;
      }
    })();
    setProj({...proj, cron: cronExpression, supposedToRunAt: firstRun});
  }

  if (proj.config?.type === undefined) {
    // This should not happen
    return null;
  }

  const changeInputType = (selectedInputType: any) => setProj({...proj, input: {...proj.input, type: selectedInputType}});
  const changeOutputType = (selectedOutputType: any) => setProj({...proj, output: {...proj.output, type: selectedOutputType}});
  const changeWebhookEncoding = (selectedOutputType: any) => {
    const output = { ...proj.output } as WebhookOutputConfig;
    output.webhookEncoding = selectedOutputType;
    setProj({...proj, output: output});
  }

  const ensureProjectName = <T extends NewProjectConfig | ProjectConfig>(project: T): T => ({
    ...project, name: he.encode(project.name || generateProjectName(mode, projects || []))
  });

  const ensureFirstProjectRun = (project: NewProjectConfig): NewProjectConfig => {
    const nextRunDate = proj.enabled ? proj.supposedToRunAt : new Date();
    return ({ ...project, supposedToRunAt: nextRunDate});
  };


  const ensureValidInput = (project: NewProjectConfig): NewProjectConfig => {
    const oldInput = proj.input;
    const newInput: InputConnectorConfig = (()=>{
      switch (oldInput.type) {
        case 'json_url': return { type: 'json_url', url: oldInput.url };
        case 'list_literal': return { type: 'list_literal', list: oldInput.list };
        case 'webhook_input': return { type: 'webhook_input', url: oldInput.url };
        case 'upload_csv_list':
        case 'upload_json_list': return { type: oldInput.type, inputKey: oldInput.inputKey, fileName: oldInput.fileName };
      }
    })();
    return ({ ...project, input: newInput});
  };

  const ensureValidOutput = (project: NewProjectConfig): NewProjectConfig => {
    const oldOutput = proj.output;
    const newOutput: OutputConfig = (()=>{
      switch (oldOutput.type) {
        case 'devnull': return { type: 'devnull' };
        case 'webhook': return { type: 'webhook', url: oldOutput.url, webhookEncoding: oldOutput.webhookEncoding, basicAuth: oldOutput.basicAuth };
        case 'save': return { type: 'save' };
      }
    })();
    return ({ ...project, output: newOutput});
  };

  const ensureValidApiParams = (project: NewProjectConfig): NewProjectConfig => {
    let newApiParams = project.config.apiParams ? { ...project.config.apiParams } : {};
    if (!newApiParams.render) {
      newApiParams.waitForSelector = undefined;
    }
    // Only SDE projects can have output format
    if (! isSDEProject(project.config.type)) {
      newApiParams = { ...newApiParams, outputFormat: undefined };
    }
    return { ...project, config: { ...project.config, apiParams: newApiParams }};
  };

  const doSaveProject = (normalizeProject: (project: NewProjectConfig)=> NewProjectConfig) => async() => {
    try {
      const {valid, problems} = validateProject(proj, settingsFromApi);
      setLocalValidatedProblems(problems);
      if (valid) {
        const savedProject = normalizeProject(proj);
        const result: {id: number} = await scraperApi.hostedScraping.saveProject(savedProject);

        const toasterMessage = project.supposedToRunAt !== undefined
          ? `Project "${savedProject.name}" has been successfully started`
          : `Project "${savedProject.name}" has been successfully saved`;
        Toaster.success(toasterMessage, toastDescription('save', savedProject.supposedToRunAt));

        refreshProject(result.id, new AbortController()); // TODO: do the abortcontroller properly

        navigate(`/projects/${result.id}`);
      } else {
        Toaster.error("Sorry, we couldn't save your project.", "Please check again the input fields");
      }
    } catch (err) {
      console.error(err);
      Toaster.error("Sorry, we couldn't save your project.", "Please try again later");
    }
  }

  const normalizeProject = lodash.flow([ensureProjectName, ensureValidApiParams, ensureValidInput, ensureValidOutput]);
  const normalizeProjectWithFirstRun = lodash.flow([ensureProjectName, ensureFirstProjectRun, ensureValidApiParams, ensureValidInput, ensureValidOutput]);

  const saveProject = doSaveProject(normalizeProject);

  const reviewSaveProjectAndStartScraping = async () => {
    const {valid, problems} = validateProject(proj, settingsFromApi);
    setLocalValidatedProblems(problems);
    if (!valid) {
      Toaster.error("Sorry, there are a few problems with your problems", "Please check again the input fields");
      return;
    }
    // Activate the modal
    setReviewProjectModalStuff({ active: true, onSave: () => {
      // And save the project when it's done
      setReviewProjectModalStuff({active: false, onSave: () => {}});
      doSaveProject(normalizeProjectWithFirstRun)()
    }});
  }

  /*
   * Doesn't navigate just saves the result and that's it.
   * It's useful for things like changing the project name.
   */
  const silentUpdateProject = async(projectToUpdate: ProjectConfig) => {
    try {
      const normalizedProject = normalizeProject(projectToUpdate as NewProjectConfig);
      await scraperApi.hostedScraping.updateProject(normalizedProject as ProjectConfig);
    } catch (err) {
      console.error(err);
      Toaster.error("Sorry, we couldn't update your project.", "Please try again later");
    }
  }

  /*
   * Normalizes the project and updates it in the database. Then navigates to the projects list
  */
  const updateProject = async() => {
    try {
      const {valid, problems} = validateProject(proj, settingsFromApi);
      setLocalValidatedProblems(problems);
      if (valid) {
        const normalizedProject = normalizeProject(proj as NewProjectConfig);
        await scraperApi.hostedScraping.updateProject(ensureProjectName(normalizedProject as ProjectConfig));
        Toaster.success(`Project "${proj.name}" has been successfully saved`, toastDescription('update', proj.supposedToRunAt));
        const projectId = (proj as ProjectConfig).id;
        if (projectId) {
          refreshProject(projectId, new AbortController()); // TODO: do the abortcontroller properly
        } else {
          refreshInBackground(new AbortController()); // TODO: do the abortcontroller properly
        }
        navigate(`/projects/${encodeURIComponent(projectId)}`);
      } else {
        Toaster.error("Sorry, we couldn't save your project.", "Please check again the input fields");
      }
    } catch (err) {
      console.error(err);
      Toaster.error("Sorry, we couldn't save your project.", "Please try again later");
    }
  }

  const updateApiParams = (key: string) => (newValue: number | string | boolean | undefined, apiParamsToUse?: AsyncApiParams) => {
    const oldApiParams = apiParamsToUse ?? proj.config.apiParams;

    const newApiParams = {
      ...oldApiParams,
      [key]: newValue,
    };
    setProj({...proj, config:{ ...proj.config, apiParams: newApiParams}});

    return newApiParams;
  }

  const showDetailsClicked = () => {
    setDetailsOpen(detailsOpen === 'up' ? 'down' : 'up');
  }

  const inputSectionLabels = getInputSectionLabelsHS(proj.config.type);

  const scrapingIntervalSettingChanged = (newValue: ScrapingIntervalSetting) => {
    setScrapingIntervalSetting(newValue);
    if (newValue === 'scheduling_disabled') {
      setProj({...proj, enabled: false, supposedToRunAt: undefined});
    } else if (newValue === 'just_scrape_once') {
      setProj({...proj, enabled: false, supposedToRunAt: new Date()});
    } else {
      setProj({...proj, enabled: true, supposedToRunAt: new Date(), scrapingInterval: newValue});
    }
  };

  const rightSidebar =
    <ProjectSummarySidebar
      variant="hosted-scraper"
      details={[
        { title: 'Title:', value:  project.config.type === 'async_urls' ? 'URLs' :  projectTypeToTitleAndDescriptionAP(project.config.type).title},
        { title: 'Input:', value:  inputTypeToTitleAndDescription(project.input.type).title},
      ]}
      config={proj.config}
      cost={cost}
      costInProgress={costLoading}
    />;

  const actionButtons =
    (mode === 'newProject' ?
      (<div className="flex flex-row flex-wrap justify-between">
        <div>
          <Button text="Back" centerAlign className="button button-secondary" onClick={backCallback} size="LG" />
          {cancelButtonVisible && <Button text="Cancel" centerAlign className="button button-tertiary ml-2" href="/projects" size="LG" />}
        </div>
        <div>
          {scrapingIntervalSetting === 'scheduling_disabled' && <Button text="Save project" className="button button-primary" disabled={ !canSaveProject } onClick={saveProject} size="LG" />}
          {scrapingIntervalSetting !== 'scheduling_disabled' && <Button text="Review & start scraping" className="button button-primary" disabled={ !canSaveProject } onClick={reviewSaveProjectAndStartScraping} size="LG" />}
        </div>
      </div>)
    : mode === 'editProject' ?
      (<div className="flex flex-row flex-wrap justify-between">
        <Button text="Cancel" className="button button-tertiary" onClick={backCallback} size="LG" />
        <Button text="Save changes" className="button button-primary" onClick={updateProject} size="LG" />
      </div>)
      : undefined);

  return (
      <ProjectBreadcrumbPage
        projectId={ projectId }
        rightSidebar={ rightSidebar }
        actionButtons={ actionButtons }
        projectName={ proj.name }
        className="px-12 pt-4"
        callback={
          (projectName: string) => {
            const changedProject = { ...proj, name: projectName };
            setProj(changedProject);
            if (mode === 'editProject') {
              silentUpdateProject(changedProject as ProjectConfig);
            }
          }
        }>
        <ProjectDetailsHead variant='hosted-scraper' configType={ proj.config.type }/>

        {
          reviewProjectModalStuff.active &&
            <HostedScraperReviewProjectModal
              project={proj}
              cost={cost}
              costInProgress={costLoading}
              onClose={() => setReviewProjectModalStuff({active: false, onSave: () => {}})}
              onStartScraping={reviewProjectModalStuff.onSave}
            />
        }

        <Separator/>

        <SectionTitle number={ 1 } title="Input settings"/>
        { (hasProblem(ConfigProblem.InvalidInput))
          ? (<div className="text-red dark:text-error-600">Invalid input</div>)
          : <></>
        }
        <Section>
          <InputComments title={inputSectionLabels.inputSectionTitle} description={inputSectionLabels.inputSectionDescription}/>
          <SectionRightHandSide>
            <div className="mb-2">Input from</div>
            <RadioGroup value={ proj.input.type } onChange={ changeInputType }>
              <div className="flex flex-row flex-wrap gap-4 mb-4">
                <Tooltip content={ tooltips.list }>
                  <RadioGroupElement key={ 'list_literal' } value={ 'list_literal' } label="List"/>
                </Tooltip>
                <Tooltip content={ tooltips.uploadFile }>
                  <RadioGroupElement key={ 'upload_csv_list' } value={ 'upload_csv_list' } label="Upload file"/>
                </Tooltip>
                <Tooltip content={ tooltips.webhookInput }>
                  <RadioGroupElement key={ 'webhook_input' } value={ 'webhook_input' } label="Webhook input"/>
                </Tooltip>
                {/* <RadioGroupElement key={'upload_json_list'} value={'upload_json_list'} label="JSON" /> */ }
                {/* <RadioGroupElement key={'google_sheet'} value={'google_sheet'} label="Google sheet" /> */ }
              </div>
            </RadioGroup>
            <InputSettings
              proj={ proj }
              problems={ allProblems }
              setProj={ setProj }
              fileUploaded={ (projectWithFileUploaded) => {
                if (mode === 'editProject') {
                  silentUpdateProject(projectWithFileUploaded as ProjectConfig);
                }
              } }
              setUploadFileProblems={ setUploadFileProblems }
            />
          </SectionRightHandSide>
        </Section>

        <Separator/>

        <Section>
          <InputComments
            title="Additional options &amp; filters"
            description={<p>For some domains you can increase the successful scraping with adding additional parameters. Find all information to our parameters <a href="https://www.scraperapi.com/documentation/curl/#Customizing-Requests" target="_blank" rel="noreferrer noopener"><span className="underline">here</span></a></p>}
            showDetailsSwitch={ detailsOpen }
            onDetailsClicked={ showDetailsClicked }
            testId="testAdditionalOptionsAndFilters"/>
          <SectionRightHandSide>
            <div className="">Select parameters and additional options</div>
            { detailsOpen === 'up' && isAmazonProject(proj.config.type) &&
              (<AmazonProjectAsyncApiParams collectorConfig={proj.config} problems={allProblems} updateApiParams={updateApiParams} checkSubscription={true} />)}
            { detailsOpen === 'up' && isGoogleProject(proj.config.type) &&
              (<GoogleProjectAsyncApiParams collectorConfig={proj.config} problems={allProblems} updateApiParams={updateApiParams} checkSubscription={true}/>)}
            { detailsOpen === 'up' && isAsyncUrlsProject(proj.config.type) &&
              (<UrlProjectAsyncApiParams variant="hosted-scraper" collectorConfig={proj.config} problems={allProblems} updateApiParams={updateApiParams} />)}
            { detailsOpen === 'up' && isWalmartProject(proj.config.type) &&
              (<WalmartProjectAsyncApiParams collectorConfig={proj.config} problems={allProblems} updateApiParams={updateApiParams} />)}
            { detailsOpen === 'up' && isEbayProject(proj.config.type) &&
              (<EbayProjectAsyncApiParams collectorConfig={proj.config} problems={allProblems} updateApiParams={updateApiParams} />)}
            { detailsOpen === 'up' && isRedfinProject(proj.config.type) &&
              (<RedfinProjectAsyncApiParams collectorConfig={proj.config} problems={allProblems} updateApiParams={updateApiParams} checkSubscription={true} />)}

          </SectionRightHandSide>
        </Section>


        <Separator/>

        <SectionTitle number={ 2 } title="Output settings"/>
        <Section>
          <InputComments title="Choose how to get the result" description="Choose an output location to which the results will be delivered." />
          <SectionRightHandSide>
            <div>Output to</div>

            <Listbox value={ proj.output.type } onChange={ changeOutputType }>
              <div className="w-full shadow">
                <Listbox.Button className={ listboxButtonClasses }>
                  <ListboxButton content={ proj.output.type === 'webhook' ? 'Webhook' : 'Download' }/>
                </Listbox.Button>
                <OptionsTransition>
                  <Listbox.Options className={ listboxOptionsClasses }>
                    <Listbox.Option className={ listboxOptionClasses } value="webhook">
                      <ListboxElement primaryText="Webhook" secondaryText="Send scraped data to a URL"/>
                    </Listbox.Option>
                    <Listbox.Option className={ listboxOptionClasses } value="save">
                      <ListboxElement primaryText="Download" secondaryText="Save the result"/>
                    </Listbox.Option>
                  </Listbox.Options>
                </OptionsTransition>
              </div>
            </Listbox>
            {/* Webhook details */}
            { proj.output.type === 'webhook' &&
                <div>
                    <Tooltip content={ tooltips.webhookURL } className="mt-2">
                        <div className="mt-2">Webhook URL</div>
                    </Tooltip>
                    <input
                        className="w-full placeholder-gray-200 dark:placeholder-neutral-200 shadow"
                        id="webhook_url_input"
                        type="text"
                        placeholder="Webhook URL"
                        value={ (proj.output as WebhookOutputConfig)?.url ?? '' }
                        onChange={(ev) => setProj({...proj, output: {...proj.output as WebhookOutputConfig, url: ev.target.value}})}
                    />
                    <RadioGroup value={proj.output.webhookEncoding ?? 'raw_encoding'} onChange={changeWebhookEncoding}>
                        <div className="flex flex-row flex-wrap gap-4 my-4">
                            <Tooltip content={ tooltips.rawEncoding }>
                              <RadioGroupElement key={'raw_encoding'} value={'raw_encoding'} label="Send as a raw file (default)" />
                            </Tooltip>
                            <Tooltip content={ tooltips.multipartFormDataEncoding }>
                              <RadioGroupElement key={'multipart_form_data_encoding'} value={'multipart_form_data_encoding'} label="Send as Multipart-form-data" />
                            </Tooltip>
                        </div>
                    </RadioGroup>
                  { hasProblem(ConfigProblem.InvalidWebhookURL) && <div className="text-red dark:text-error-600">Invalid URL</div> }
                  { hasProblem(ConfigProblem.MissingWebhookURL) && <div className="text-red dark:text-error-600">Webhook URL is missing</div> }
                </div>
            }
            {/* Save details */}
            { (proj.output.type === 'save' || proj.output.type === 'webhook' ) && isSDEProject(proj.config.type) &&
              (<div>
                <div className="mt-2">Select format</div>
                <AdditionalOptionsListbox
                  value={proj.config.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
                  options={[
                    { value: 'csv', text: 'CSV' },
                    { value: 'json', text: 'JSON' }
                  ]}
                  callback={(newValue) => {
                    const outputFormat = (newValue === 'json' ? 'json' : 'csv') as OutputFormat;
                    const config = { ...proj.config, apiParams: { ...proj.config.apiParams, outputFormat } };
                    setProj({...proj, config });
                  }}
                  />
              </div>)
            }

          </SectionRightHandSide>
        </Section>

        <Separator/>

        <Section>
          <InputComments title="Scraping frequency" description="Choose the scraping frequency for your data extraction to keep your data up-to-date." />
          <SectionRightHandSide>
            {/* <div className="w-full flex flex-row flex-wrap gap-10 items-center">
                <SchedulerStartSwitch enabled={proj.enabled} callback={(enabled: boolean) => setProj({...proj, enabled, supposedToRunAt: enabled ? (project.supposedToRunAt ?? new Date()) : undefined})} />
                { proj.enabled && proj.scrapingInterval !== 'cron' && <div>
                  <Tooltip content={tooltips.scheduleTime}>
                    <div>Schedule time</div>
                  </Tooltip>
                  <DatePicker
                    onChange={(newDate: Date) => setProj({...proj, supposedToRunAt: dateOrNow(newDate) }) } // Don't let to set a date in the past
                    selected={scheduledDateOrNow(proj)}
                    showTimeInput={true}
                    selectsRange={false}
                      dateFormat="yyyy-MM-dd h:mm aa"
                  />
                </div>}
              </div> */ }
            <Tooltip content={ tooltips.scrapingInterval } className="mt-4">
              <div className="text-base text-gray dark:text-neutral-600 mt-4">Scraping frequency</div>
            </Tooltip>
            <Listbox value={ scrapingIntervalSetting } onChange={ scrapingIntervalSettingChanged }>
              <div className="w-full shadow">
                <Listbox.Button className={ listboxButtonClasses }>
                  <ListboxButton content={ schedulingInterval(scrapingIntervalSetting).text }/>
                </Listbox.Button>
                <OptionsTransition>
                  <Listbox.Options className={ listboxOptionsClasses }>
                    <Listbox.Option className={ listboxOptionClasses } value="scheduling_disabled">
                      <ListboxElement primaryText={ mode==='newProject' ? "Don't scrape now" : "Scheduling disabled"} />
                    </Listbox.Option>
                    <Listbox.Option className={ listboxOptionClasses } value="just_scrape_once">
                      <ListboxElement primaryText="Just scrape once"/>
                    </Listbox.Option>
                    {
                      validSchedulingIntervals.map((schedulingType) => {
                        const { text, value } = schedulingInterval(schedulingType);
                        return (<Listbox.Option className={ listboxOptionClasses } key={ value } value={ value }>
                          <ListboxElement primaryText={ text }/>
                        </Listbox.Option>);
                      })
                    }
                  </Listbox.Options>
                </OptionsTransition>
              </div>
            </Listbox>
            { scrapingIntervalSetting === 'cron'
              && <CronScheduling cronExpression={ proj.cron } callback={ updateCron }/> }
            <div className="text-lightGray dark:text-neutral-500 mt-4">Next runs:</div>
            {
              scrapingIntervalSetting === 'scheduling_disabled' ? <></> :
                scrapingIntervalSetting === 'just_scrape_once' ? <div className="text-lightGray dark:text-neutral-500">{ '1. Now' }</div> :
                nextRuns.map((run, idx) => (<div key={idx} className="text-lightGray dark:text-neutral-500">{`${idx+1}. ${formatDate(run)}`}</div>))
            }
          </SectionRightHandSide>
        </Section>

        <Separator/>

        <NotificationPreferences proj={ proj } setProj={ setProj }/>

      </ProjectBreadcrumbPage>
  );
}
