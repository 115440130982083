import { SDEContent } from "./sdecontentTypes";

export const UnknownContent: SDEContent = {
  projectType: 'unknown',
  projectCategory: 'unknown',
  apiUrlFn: (apiHost: string) => {
    return ``;
  },
  docsSection: '',
  docsSectionAsync: '',
  inputKey: 'input',
  inputsKey: 'inputs',
  validatorFunction: (input: string) => false,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Inputs',
      listLiteralInputPlaceholder: 'Enter one input per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input",
      inputSectionDescription: '',
    },
    projectContent: {
        title: 'Unknown project',
        description: '',
        icon: 'amazon',
        inputTitle: 'Input',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input search term',
      inputSectionDescription: 'Enter inputs',
    },
    projectContent: {
        title: 'Unknown project type',
        description: '',
        icon: 'unknown',
    },
    enterInputsPlaceholder: 'input',
    enterInputsMessage: 'Enter keyword for scraping',
    codeViewPlaceHolder: 'Please provide a valid search term',
    dropdownTitle: 'unknown',
  }
};
