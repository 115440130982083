import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  isRouteErrorResponse,
  useFetcher,
  useLocation,
  useNavigate,
  useParams,
  useRevalidator,
  useRouteError
} from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { format } from "date-fns";

import { ReactComponent as XIcon } from "assets/icons/x-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

import scraperApi, { ApiError, ApiErrorResponse } from "api";
import { fmtCurrency, fmtDate } from "utils";
import { isAnnualPlan, manualRenewToThisIfScheduled } from "utils/planUtils";

import BookSalesCallModalContent from "components/BookSalesCallModalContent";
import CouponCodeInput from "components/CouponCodeInput";
import { useFeatureSwitch } from "components/FeatureSwitch";
import FormSubmittingSpinner from "components/FormSubmittingSpinner";
import Button from "components/Button";
import Form from "components/Form";
import Spinner from "components/Spinner";
import SubmitButton from "components/SubmitButton";
import TaggedText from "components/TaggedText";
import PasswordChangeForm from "components/PasswordChangeForm";
import Toaster from "components/Toaster";

import useFormattedActionError from "hooks/useFormattedActionError";
import { useStatefulNavigate } from "hooks/useStatefulNavigate";

import Messages from "misc/Messages";

import { Plan, UserContextType, useUserProvider } from "providers/UserProvider";
import { useChargebeePortals } from "providers/ChargebeePortalProvider";
import { useIssues } from "providers/IssuesProvider";

import IRouterActionError from "routes/dataroutes/IRouterActionError";
import { useUser } from "routes/dataroutes/UserData";

import OldUpgradeSubscriptionModal from "./OldUpgradeSubscriptionModal";
import UpgradeSubscriptionModal from "./UpgradeSubscriptionModal";

import {
  CancellationSurveyModalContent,
  CancelSubscriptionModalContent,
  CheckingUnpaidInvoicesModalContent,
  DisabledBecauseOfUnpaidInvoicesModalContent
} from "./CancelSubscription";
import { ErrorMessage } from "./ErrorMessage";
import ExtLink from "components/ExtLink";

// Re-export
export { HostedScraperErrorReportModal, HostedScraperCancelJobModal, HostedScraperJobInfoModal } from './HostedScraperModals';


interface IModalProps {
  headline: string;
  children: ReactElement<any, any> | ReactElement<any, any>[];
  onClose?: () => void;
  closeOnEscapeKey?: boolean;
}


export const isCouponError = (apiError: ApiError<ApiErrorResponse>) => {
  return ([
    "err_fp_user_not_included",
    "err_fp_coupon_mismatch",
    "err_fp_user_denied",
    "err_fp_promo_not_active",
    "err_fp_no_offer",
    "err_coupon_not_active",
    "err_coupon_not_applicable",
    "err_coupon_not_supported",
  ].includes(apiError.error_code));
};

export const getTotalPriceToInvoice = (plan?: Plan, subscription?: UserContextType["subscription"]) => {
  return (manualRenewToThisIfScheduled(subscription?.scheduledSubscription?.plan_id) && (subscription?.scheduledSubscription?.discount_price || subscription?.scheduledSubscription?.plan_unit_price)) ||
    plan?.discount_price ||
    plan?.price ||
    subscription?.plan_amount ||
    0;
}

/**
 * This should be used for modals that are bound to a URL
 */
interface CloseModalOptions {
  blockNavigationIf?: boolean;
  goBackInHistoryOnClose?: boolean;
}

export const useCloseModal = (opts?: CloseModalOptions) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as { backgroundLocation?: Location };

  const closeModal = useCallback(() => {
    if (opts?.blockNavigationIf) {
      return;
    }

    if (opts?.goBackInHistoryOnClose) {
      navigate(-1);
    } else {
      const prevPath = state?.backgroundLocation || "/";
      navigate(prevPath);
    }
  }, [state?.backgroundLocation, navigate, opts]);

  return closeModal;
};


export default function Modal({ headline, children, onClose, closeOnEscapeKey }: IModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  const closeOnEsc = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape" && onClose && closeOnEscapeKey) {
      onClose();
    }
  }, [onClose, closeOnEscapeKey]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (closeOnEscapeKey) {
      document.addEventListener("keydown", closeOnEsc);
      return () => document.removeEventListener("keydown", closeOnEsc);
    }
  }, [onClose, closeOnEscapeKey, closeOnEsc]);

  // workaround for the 'There are no focusable elements inside the <FocusTrap />' error
  let focusRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => { }} initialFocus={focusRef}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 dark:bg-neutral-500 bg-opacity-75 pointer-events-none" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto" ref={focusRef} >
          <div className="flex items-end justify-center min-h-full p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full overflow-hidden transition-all transform bg-white sm:min-w-[500px] sm:max-w-min">
                <div>
                  <div className="flex items-center justify-between px-5 py-4 border-b border-b-borderColor dark:border-b-neutral-200">
                    <Dialog.Title
                      as="span"
                      className="text-lg leading-6 text-brandDarkest dark:text-primary-800"
                    >
                      {headline}
                    </Dialog.Title>

                    {onClose && (
                      <XIcon
                        className="w-8 h-8 p-1.5 transition-colors rounded-sm cursor-pointer text-gray dark:text-neutral-600 hover:text-gray-800 dark:hover:text-neutral-800 hover:bg-slate-100"
                        onClick={onClose}
                      />
                    )}
                  </div>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function ModalContent({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex flex-col p-5 gap-y-12">
      {children}
    </div>
  );
}

export function ModalSections({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex flex-col gap-y-4 text-gray dark:text-neutral-600">
      {children}
    </div>
  );
}

export function ModalButtonsSection({ children }: { children?: React.ReactNode }) {
  return (
    <div className="flex justify-end gap-x-4 items-center">
      {children}
    </div>
  );
}

export function ChangePasswordModal() {
  const user = useUser();
  const revalidator = useRevalidator();
  const methods = useForm({
    mode: "onChange"
  });
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });

  const changePasswordCallback = useCallback(
    async (oldPassword: string, newPassword: string) => {
      await scraperApi.auth.changePassword(oldPassword, newPassword);
      // TODO probably there's no need to reload the data here. changing a password should not affect anything
      revalidator.revalidate();
      goBack();
    },
    [
      goBack, revalidator
    ]
  );

  return (
    <Modal
      headline={user?.hasPassword ? "Change Password" : "Set Password"}
      onClose={goBack}
    >
      <div className="mx-5 my-8">
        <PasswordChangeForm
          buttonText={user?.hasPassword ? "Change Password" : "Set Password"}
          changePasswordCallback={changePasswordCallback}
          cancelButtonAction={goBack}
        />
      </div>
    </Modal>
  );
}

export function CancelSubscriptionModal() {
  const cancelSubscriptionFetcher = useFetcher();
  const goBack = useCloseModal({ blockNavigationIf: cancelSubscriptionFetcher.state !== "idle" });
  const showCancellationSurvey = useFeatureSwitch("REACT_APP_CANCELLATION_SURVEY_USERS");
  const unpaidInvoicesFetcher = useFetcher();

  useEffect(() => {
    if ((unpaidInvoicesFetcher.state === "idle") && !unpaidInvoicesFetcher.data) {
      unpaidInvoicesFetcher.load("/billing-data/invoices/not-paid");
    }
  });

  useEffect(() => {
    if (cancelSubscriptionFetcher.data === "OK" && !showCancellationSurvey) {
      goBack();
    }
  }, [cancelSubscriptionFetcher.data, goBack, showCancellationSurvey]);

  const ModalWrapper = useMemo(() => ({ children }: { children: JSX.Element }) => {
    return <Modal headline="Cancel Subscription" onClose={goBack}>
      {children}
    </Modal>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((unpaidInvoicesFetcher.state === "loading") || !unpaidInvoicesFetcher.data) {
    return <ModalWrapper>
      <CheckingUnpaidInvoicesModalContent />
    </ModalWrapper>;
  }

  if ((unpaidInvoicesFetcher.state === "idle") && (unpaidInvoicesFetcher.data?.count !== undefined) && (unpaidInvoicesFetcher.data.count > 0)) {
    return (
      <ModalWrapper>
        <DisabledBecauseOfUnpaidInvoicesModalContent closeModalCallback={goBack} />
      </ModalWrapper>
    );
  }

  if ((cancelSubscriptionFetcher.data === "OK") && showCancellationSurvey) {
    // subscription has been cancelled, show the cancellation survey
    return (
      <ModalWrapper>
        <CancellationSurveyModalContent closeModalCallback={goBack} />
      </ModalWrapper>
    );
  }

  if ((cancelSubscriptionFetcher.data !== "OK") || !showCancellationSurvey) {
    // subscription is not cancelled yet, or there was an error cancelling it, show the cancellation content
    return (
      <ModalWrapper>
        <CancelSubscriptionModalContent fetcher={cancelSubscriptionFetcher} closeModalCallback={goBack} />
      </ModalWrapper>
    );
  }

  return <></>; // TODO maybe we should better throw an exception here?
}

export function KeepSubscriptionModal() {
  const keepSubscriptionFetcher = useFetcher<string | IRouterActionError>();
  const goBack = useCloseModal({ blockNavigationIf: keepSubscriptionFetcher.state !== "idle" });

  const { subscription } = useUserProvider();

  useEffect(() => {
    if (keepSubscriptionFetcher.data === "OK") {
      goBack();
    }
  }, [goBack, keepSubscriptionFetcher.data]);

  return (
    <Modal headline="Reactivate Subscription" onClose={goBack}>
      <ModalContent>
        <ModalSections>
          <div>Your subscription was cancelled and will end on {fmtDate(new Date((subscription?.current_term_end || 0) * 1000))}.</div>
          <TaggedText message="If you have any follow up questions or want to subscribe to a custom plan, please [contact our support team|contact_support]."
            tagCallbacks={{ contact_support: goBack }}
          />
          <div>You cancelled your subscription by accident? Just click on the "Reactivate subscription" button below and your subscription will be renewed with the next cycle.</div>
          <ErrorMessage errorMessage={useFormattedActionError(keepSubscriptionFetcher.data)} closeModalCallback={goBack} />
        </ModalSections>
        <keepSubscriptionFetcher.Form method="POST" action="/billing-data/subscription/keep">
          <ModalButtonsSection>
            <SubmitButton
              text="Reactivate subscription"
              size="MD"
              icon={{ absolute: false }}
              className="button button-tertiary"
            />
            <Button
              text="Ok"
              size="MD"
              onClick={goBack}
            />
          </ModalButtonsSection>
        </keepSubscriptionFetcher.Form>
      </ModalContent>
    </Modal>
  );
}

export function CancelSubscriptionChangeModal() {
  const revalidator = useRevalidator();
  const { refresh } = useUserProvider();
  const goBack = useCloseModal();
  const [error, setError] = useState<string>();

  const onSubmit = useCallback(async () => {
    try {
      await scraperApi.subscription.removeScheduledChanges();
      revalidator.revalidate();
      await refresh();
      goBack();
    } catch (error) {
      setError((error as Error).message || "There was an error cancelling your changes");
    }
  }, [goBack, revalidator, refresh]);

  return (
    <Modal headline="Cancel Subscription Change" onClose={goBack}>
      <ModalContent>
        <ModalSections>
          <div>Are you sure you want to cancel your subscription change?</div>
          <div>Your scheduled changes will be canceled</div>
          <ErrorMessage errorMessage={error} closeModalCallback={goBack} />
        </ModalSections>
        <ModalButtonsSection>
          <Button text="Keep Change" className="button button-secondary" onClick={goBack} size="MD" />
          <Button
            text="Cancel Change"
            className="button button-primary destructive"
            onClick={onSubmit}
            size="MD"
          />
        </ModalButtonsSection>
      </ModalContent>
    </Modal>
  );
}

export function ConfirmRenewalModal() {
  const revalidator = useRevalidator();
  const user = useUser();
  const { subscription, plans, refresh } = useUserProvider();

  const methods = useForm();
  const { setError } = methods;

  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });

  const [formError, setFormError] = useState<string>();

  let { planId } = useParams() as { planId: string };

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      const couponDetails = subscription?.couponDetails;
      if (couponDetails?.find(c => c.coupon_code === data.couponCode)) {
        return setError("coupon", {
          type: "custom",
          message: "That coupon has already been used"
        });
      }

      if (!subscription?.id) {
        return setFormError("There is no subscription to renew");
      }

      try {
        await scraperApi.subscription.renew(subscription?.id, [data.couponCode], isAnnualPlan(subscription?.plan_id) ? true : undefined);
        revalidator.revalidate();
        await refresh();
        goBack();
      } catch (error) {
        if (error instanceof ApiError) {
          const apiError = error as ApiError<ApiErrorResponse>;
          if (isCouponError(apiError)) {
            return setError("coupon", {
              type: "custom",
              message: apiError.details?.message || apiError.message
            });
          }

          if (apiError.error_code === "err_recently_renewed") {
            return setFormError(apiError.message);
          }
        }
      }
    },
    [goBack, revalidator, refresh, setError, subscription]
  );

  const currentPlan = plans?.find(p => p.id === planId);
  const price = getTotalPriceToInvoice(currentPlan, subscription);
  const isInvoiceNeeded = !subscription || (subscription.next_renewal_at === subscription.next_billing_at);

  if (user?.isRenewalAllowed) {
    return (
      <Modal headline="Confirm Renew Subscription" onClose={goBack}>
        <Form methods={methods} onSubmit={onSubmit}>
          <ModalContent>
            <ModalSections>
              <div>Are you sure you want to renew your subscription?</div>
              <div>
                By renewing your subscription, we will reset your credit counters
                {isInvoiceNeeded && (<>, Invoice <span className="font-bold">{fmtCurrency(price / 100)}</span> now</>)}
                {(isAnnualPlan(planId) && !isInvoiceNeeded) ? " and shorten your annual subscription by one month." : " and set your billing date to today."}
              </div>
              {(user?.canUseAllCoupons || (user?.canUseCoupons && !isAnnualPlan(currentPlan?.id))) && (
                <CouponCodeInput
                  couponDetails={subscription?.couponDetails}
                  targetPlanSlug={planId}
                />
              )}
              <ErrorMessage errorMessage={formError} closeModalCallback={goBack} />
            </ModalSections>
            <ModalButtonsSection>
              <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
              {/* TODO replace with <SubmitButton /> */}
              <Button
                text="Renew Subscription"
                className="button button-primary"
                type="submit"
                icon={{ Icon: FormSubmittingSpinner }}
                onClick={() => {
                  setFormError(undefined);
                }}
                size="MD"
              />
            </ModalButtonsSection>
          </ModalContent>
        </Form>
      </Modal>
    );
  } else {
    return (
      <Modal headline="Confirm Renew Subscription" onClose={goBack}>
        <ModalContent>
          <ModalSections>
            <TaggedText message={Messages.renewalDisabledTaggedMessage} />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Ok" className="button button-primary" onClick={goBack} size="MD" />
          </ModalButtonsSection>
        </ModalContent>
      </Modal>
    );
  }
}

function ChangeAnnualModal(
  {
    method
  }: {
    method: "Upgrade" | "Downgrade";
  }
) {
  const revalidator = useRevalidator();
  const { subscription, plans, refresh } = useUserProvider();
  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const [error, setError] = useState<string>();

  const methoding = method.toLowerCase().slice(0, -1) + "ing";
  const { planId } = useParams() as { planId: string };
  const targetPlan = plans?.find(p => p.id === planId)!;
  const price = getTotalPriceToInvoice(targetPlan, subscription);

  const onSubmit = useCallback(async () => {
    try {
      await scraperApi.subscription.update(planId, false, undefined, false);
      revalidator.revalidate();
      await refresh();
      goBack();
    } catch (error) {
      const err = error as any;
      setError(err.message || `There was an error ${methoding} your subscription`);
    }
  }, [planId, revalidator, refresh, goBack, setError, methoding]);

  // TODO should add the coupon input here as well
  return (
    <Modal headline={`${method} Subscription`} onClose={goBack}>
      <Form methods={methods} onSubmit={onSubmit}>
        <ModalContent>
          <ModalSections>
            <div>
              Are you sure you want to {method.toLowerCase()} your subscription to{" "}
              <span className="font-bold capitalize">{targetPlan.name}</span>
              ?
            </div>
            <div>
              By {methoding} your subscription, we will reset your credit counters
              and invoice{" "}
              <span className="font-bold">{fmtCurrency(price / 100)}</span>{" "}
              on {fmtDate(new Date(subscription?.current_term_end! * 1000))}, when your annual subscription ends.
              <TaggedText
                message={` If you would like to end your annual subscription and ${method.toLowerCase()} earlier, please [contact our support team|contact_support].`}
                tagCallbacks={{ contact_support: goBack }}
              />
            </div>
            <ErrorMessage errorMessage={error} closeModalCallback={goBack} />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
            {/* TODO replace with <SubmitButton/> */}
            <Button
              text={`${method} Subscription`}
              className="button button-primary"
              type="submit"
              icon={{ Icon: FormSubmittingSpinner }}
              onClick={() => {
                setError(undefined);
              }}
              size="MD"
            />
          </ModalButtonsSection>
        </ModalContent>
      </Form>
    </Modal>
  );
}

export function UpgradeAnnualModal() {
  return <ChangeAnnualModal method="Upgrade" />;
}

export function UpgradeModal() {
  const useNewDialogForBillingAddress = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_BILLING_ADDRESS_USERS");
  const useNewDialogForPaymentSources = useFeatureSwitch("REACT_APP_NEW_SUBSCRIPTION_DIALOGS_PAYMENT_SOURCES_USERS");

  return (useNewDialogForBillingAddress || useNewDialogForPaymentSources) ?
    <UpgradeSubscriptionModal /> :
    <OldUpgradeSubscriptionModal />;
}

export function UpgradeErrorModal() {
  const error = useRouteError();
  const closeModal = useCloseModal();

  if (isRouteErrorResponse(error)) {
    // internal router error, let it bubble up
    throw error;
  }

  return (
    <Modal headline="Error Upgrading Subscription" onClose={closeModal}>
      <ModalContent>
        <ModalSections>
          <div>There was an unexpected error while trying to upgrade your subscription.</div>
          <TaggedText
            message="Please try again later or [contact our support team|contact_support]."
            tagCallbacks={{ contact_support: closeModal }}
          />
        </ModalSections>
      </ModalContent>
    </Modal>
  );
}

export function DowngradeAnnualModal() {
  return <ChangeAnnualModal method="Downgrade" />;
}

export function DowngradeModal() {
  const revalidator = useRevalidator();
  const { plans, subscription, refresh } = useUserProvider();

  let { planId } = useParams() as { planId: string };
  const methods = useForm();
  const { handleSubmit } = methods;
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const [formError, setFormError] = useState<string>();

  const currentPlan = plans?.find(p => p.id === planId);
  const price = getTotalPriceToInvoice(currentPlan, subscription);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      try {
        await scraperApi.subscription.update(
          planId,
          data.immediate,
          undefined,
          false
        );
        revalidator.revalidate();
        await refresh();
        goBack();
      } catch (error) {
        setFormError((error as Error).message || "There was an error downgrading your subscription.");
      }
    },
    [goBack, planId, revalidator, refresh]
  );

  return (
    <Modal headline="Downgrade Subscription" onClose={goBack}>
      <Form methods={methods} onSubmit={onSubmit}>
        <ModalContent>
          <ModalSections>
            <div>
              Are you sure you want to downgrade your subscription to{" "}
              <span className="font-bold capitalize">
                {currentPlan?.name.replace("202206", "").trim()}
              </span>
              ?
            </div>
            <div>
              By downgrading your subscription, we will reset your credit
              counters, Invoice{" "}
              <span className="font-bold">
                {fmtCurrency(price / 100)}{" "}
              </span>{" "}
              and update your billing date either immediately or at the end of
              current cycle.
            </div>
            <ErrorMessage errorMessage={formError} closeModalCallback={goBack} />
          </ModalSections>
          <div className="flex flex-col items-end gap-y-2 gap-x-1">
            {/* TODO replace with <SubmitButton/> */}
            <Button
              text="Downgrade at End of Cycle"
              className="button button-primary destructive"
              icon={methods.formState.isSubmitting ? { Icon: FormSubmittingSpinner } : undefined}
              onClick={() => {
                setFormError(undefined);
                handleSubmit(d => onSubmit({ ...d, immediate: false }))();
              }}
              size="SM"
            />
            <div className="flex justify-between w-full">
              <Button text="Cancel" className="button button-secondary" onClick={goBack} size="SM" />

              {/* TODO replace with <SubmitButton/> */}
              <Button
                text="Downgrade Immediately"
                className="button button-primary destructive"
                icon={methods.formState.isSubmitting ? { Icon: FormSubmittingSpinner } : undefined}
                onClick={() => {
                  setFormError(undefined);
                  handleSubmit(d => onSubmit({ ...d, immediate: true }))();
                }}
                size="SM"
              />
            </div>
          </div>
        </ModalContent>
      </Form>
    </Modal>
  );
}

export function AutoRenewalModal() {
  const user = useUser();
  const revalidator = useRevalidator();
  const { refresh } = useUserProvider();

  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const [formError, setFormError] = useState<string>();

  let { ceiling } = useParams() as { ceiling: string };
  const onSubmit = useCallback(async () => {
    try {
      await scraperApi.subscription.autoRenew(ceiling);
      revalidator.revalidate();
      await refresh();
      goBack();
    } catch (error) {
      setFormError("There was an error changing your auto-renewal settings");
    }
  }, [ceiling, goBack, revalidator, refresh]);

  if (user?.isRenewalAllowed) {
    return (
      <Modal headline="Automatic renewals" onClose={goBack}>
        <Form methods={methods} onSubmit={onSubmit}>
          <ModalContent>
            <ModalSections>
              <div>Are you sure you want to auto renew your subscription?</div>
              <div>
                <p>
                  Your subscription will be renewed automatically after you have
                  used <span className="font-bold">{ceiling}%</span> of your
                  credits.
                </p>
              </div>
              <ErrorMessage errorMessage={formError} closeModalCallback={goBack} />
            </ModalSections>
            <ModalButtonsSection>
              <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
              {/* TODO replace with <SubmitButton/> */}
              <Button
                text={`Auto Renew at ${ceiling}%`}
                className="button button-primary"
                type="submit"
                icon={{ Icon: FormSubmittingSpinner }}
                onClick={() => {
                  setFormError(undefined);
                }}
                size="MD"
              />
            </ModalButtonsSection>
          </ModalContent>
        </Form>
      </Modal>
    );
  } else {
    return (
      <Modal headline="Automatic renewals" onClose={goBack}>
        <ModalContent>
          <ModalSections>
            <TaggedText message={Messages.renewalDisabledTaggedMessage} />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Ok" className="button button-primary" onClick={goBack} size="MD" />
          </ModalButtonsSection>
        </ModalContent>
      </Modal>
    );
  }
}

export function ClearAutoRenewalModal() {
  const revalidator = useRevalidator();
  const { refresh, subscription } = useUserProvider();
  let nextRenewalAt;

  if (subscription && subscription.next_renewal_at) {
    nextRenewalAt = `(next renewal ${format(subscription.next_renewal_at * 1000, 'EEE, MMMM do yyyy')})`;
  }

  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const [formError, setFormError] = useState<string>();

  const onSubmit = useCallback(async () => {
    try {
      await scraperApi.subscription.clearAutoRenew();
      revalidator.revalidate();
      await refresh();
      goBack();
    } catch (error) {
      setFormError("There was an error changing your auto-renewal settings");
    }
  }, [goBack, revalidator, refresh]);

  return (
    <Modal headline="Standard renewal cycle" onClose={goBack}>
      <Form methods={methods} onSubmit={onSubmit}>
        <ModalContent>
          <ModalSections>
            <p>
              Are you sure you want to set your renewal to the default renewal settings?
            </p>
            <p>
              Your subscription will be renewed by the end of your billing cycle{nextRenewalAt ? ` ${nextRenewalAt}, ` : ', '}
              even if you run out of all your credits before the end of the billing cycle.
            </p>
            <p>You can learn more about our renewal feature and other options in our {' '}
              <ExtLink className="underline" to="https://docs.scraperapi.com/faq/plans-and-billing/what-happens-if-i-run-out-of-credits-before-the-end-of-my-current-subscription">documentation</ExtLink>.
            </p>
            <ErrorMessage errorMessage={formError} closeModalCallback={goBack} />
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
            {/* TODO replace with <SubmitButton/> */}
            <Button
              text="OK"
              className="button button-primary"
              type="submit"
              icon={{ Icon: FormSubmittingSpinner }}
              onClick={() => {
                setFormError(undefined);
              }}
              size="MD"
            />
          </ModalButtonsSection>
        </ModalContent>
      </Form>
    </Modal>
  );
}

export function CouponsModal() {
  const revalidator = useRevalidator();
  const { subscription, refresh } = useUserProvider();
  const methods = useForm();
  const { setError, clearErrors } = methods;
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });

  const checkAlreadyUsedCoupon = useCallback((couponCode: string) => {
    const couponDetails = subscription?.couponDetails;
    if (couponDetails?.find(c => c.coupon_code === couponCode)) {
      setError("coupon", {
        type: "custom",
        message: "That coupon has already been used"
      });
      return true;
    }

    clearErrors("coupon");
    return false;
  }, [subscription, setError, clearErrors]);

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      if (!checkAlreadyUsedCoupon(data.couponCode)) {
        try {
          await scraperApi.auth.coupons(data.couponCode);
          revalidator.revalidate();
          await refresh();
          goBack();
        } catch (error) {
          const err = error as any;
          return setError("coupon", {
            type: "custom",
            message: err.message || "There was an error adding the coupon"
          });
        }

      }
    },
    [goBack, revalidator, refresh, setError, checkAlreadyUsedCoupon]
  );

  return (
    <Modal headline="Manage Coupons" onClose={goBack}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="flex flex-col p-5 space-y-4">
          <div className="flex flex-col space-y-6 text-gray dark:text-neutral-600">
            {subscription?.couponDetails?.map((coupon, index) => {
              return (
                <div
                  key={`${coupon.coupon_code}-${coupon.coupon_type}-${index}`}
                >
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <div className="font-bold">{coupon.coupon_code}</div>
                      <div className="text-sm">
                        Active since{" "}
                        {coupon.added_at && fmtDate(new Date(coupon.added_at))}
                      </div>
                    </div>
                    <div className="">{coupon.discount}</div>
                  </div>
                </div>
              );
            })}
            {subscription?.couponDetails?.length && (
              <hr className="text-borderColor dark:text-neutral-200" />
            )}
          </div>
          <CouponCodeInput
            couponDetails={subscription?.couponDetails}
            className="space-y-2"
          />
          <ModalButtonsSection>
            <Button text="Dismiss" className="button button-secondary" onClick={goBack} size="MD" />
            {/* TODO replace with <SubmitButton/> */}
            <Button
              text="Add coupon"
              className="button button-primary"
              type="submit"
              icon={{ Icon: FormSubmittingSpinner }}
              onClick={() => { }}
              size="MD"
            />
          </ModalButtonsSection>
        </div>
      </Form>
    </Modal>
  );
}

export function ApiKeyManagement() {
  const [apiKeys, setApiKeys] = useState([]);
  const [removing, setRemoving] = useState(false);
  const methods = useForm();
  useEffect(() => {
    const controller = new AbortController();

    (async () => {
      const apiKeys = await scraperApi.apiKeys.fetchApiKeys({
        signal: controller.signal
      });
      setApiKeys(apiKeys);
    })();

    return () => {
      controller.abort();
    };
  }, []);

  const removeApiKeyCallback = useCallback(
    async (apiKey: string) => {
      setRemoving(true);
      try {
        await scraperApi.apiKeys.removeApiKey(apiKey);
        setApiKeys(apiKeys.filter((key: any) => key.apiKey !== apiKey));
      } finally {
        setRemoving(false);
      }
    },
    [apiKeys, setApiKeys, setRemoving]
  );

  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const statefulNavigate = useStatefulNavigate();
  const goToConfirm = useCallback(() => statefulNavigate("/api-keys/confirm"), [statefulNavigate]);

  return (
    <Modal headline="Manage Api Keys" onClose={goBack}>
      <div className="flex flex-col p-5 space-y-4">
        <div className="flex flex-col space-y-6 text-gray dark:text-neutral-600">
          {apiKeys.length === 0 && (
            <Spinner className="self-center w-5 h-5 mr-3 -ml-1 text-black animate-spin" />
          )}
          {apiKeys.map((key: any) => {
            return (
              <div key={key.apiKey}>
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <div className="font-mono font-bold">{key.apiKey}</div>

                    {key.expireAt ? (
                      <div className="text-sm text-red dark:text-error-600">
                        Expires at{" "}
                        {key.expireAt && fmtDate(new Date(key.expireAt))}
                      </div>
                    ) : (
                      <div className="text-sm">
                        Active since{" "}
                        {key.addedAt && fmtDate(new Date(key.addedAt))}
                      </div>
                    )}
                  </div>
                  {key.expireAt && (
                    <div className="flex items-center">
                      {removing ? (
                        <Spinner className="w-5 h-5 animate-spin" />
                      ) : (
                        <TrashIcon
                          className="cursor-pointer text-black/30 hover:text-black"
                          onClick={async () => { return removeApiKeyCallback(key.apiKey) }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <ModalButtonsSection>
          <Button
            text="New API Key"
            className="button button-primary"
            onClick={goToConfirm}
            size="MD"
          />
        </ModalButtonsSection>
      </div>
    </Modal>
  );
}

export function ConfirmApiKeyChangeModal() {
  const revalidator = useRevalidator();
  const { refresh } = useUserProvider();
  const [error, setError] = useState<string>();
  const statefulNavigate = useStatefulNavigate();
  const goBack = useCallback(() => statefulNavigate("/api-keys"), [statefulNavigate]);
  const user = useUser();

  const onSubmit = useCallback(async () => {
    try {
      await scraperApi.apiKeys.newApiKey();
      revalidator.revalidate();
      await refresh();
      goBack();
    } catch (error) {
      setError((error as Error).message || "There was an error updating your API key");
    }
  }, [goBack, revalidator, refresh]);

  return (
    <Modal headline="Confirm New API Key" onClose={goBack}>
      <ModalContent>
        <ModalSections>
          <div>Are you sure you want to generate a new API key?</div>
          {user?.apiKeyExpirationDays && (
            <div>
              Your current key will remain active for {user.apiKeyExpirationDays} more day{user.apiKeyExpirationDays > 1 ? "s" : ""}.
              After that it will be disabled and you will only be able to scrape with the new key.
            </div>
          )}
          {!user?.apiKeyExpirationDays && (
            <div>
              Your current key will be disabled immediately, so you won't be able
              to use that for scraping anymore.
            </div>
          )}
          <ErrorMessage errorMessage={error} closeModalCallback={goBack} />
        </ModalSections>
        <ModalButtonsSection>
          <Button text="Cancel" className="button button-secondary" onClick={goBack} size="MD" />
          <Button text="OK" className="button button-primary" onClick={onSubmit} size="MD" />
        </ModalButtonsSection>
      </ModalContent>
    </Modal>
  );
}

export function CancelEnterpriseModal() {
  const goBack = useCloseModal();
  const { subscription } = useUserProvider();

  return (
    <Modal headline="Cancel Subscription" onClose={goBack}>
      <ModalContent>
        <div className="text-gray dark:text-neutral-600">
          <TaggedText message={(subscription?.name || "Enterprise") + " subscriptions can not be cancelled manually. Please reach out to our [support team|contact_support]"} />
          <span> or send an email to{" "}
            <a
              className="text-brandPrimary dark:text-primary-600 hover:text-brandPrimary-900 dark:hover:text-primary-700"
              href="mailto:support@scraperapi.com"
            >
              support@scraperapi.com
            </a>
          </span>
        </div>
        <ModalButtonsSection>
          <Button text="Ok" className="button button-primary" onClick={goBack} size="MD" />
        </ModalButtonsSection>
      </ModalContent>
    </Modal>
  );
}

export function PayNowModal() {
  const { generatePayNowLink } = useChargebeePortals();
  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const { openChargebeePortal } = useChargebeePortals();
  const revalidator = useRevalidator();
  const { refresh } = useUserProvider();
  const issuesContext = useIssues();

  const [payNowLinkOpened, setPayNowLinkOpened] = useState<boolean>();

  const onSubmit = useCallback(
    async () => {
      const payNowLink = await generatePayNowLink();

      window.open(payNowLink, "_blank");

      setPayNowLinkOpened(true);
    },
    [setPayNowLinkOpened, generatePayNowLink]
  );

  const onDone = useCallback(
    async () => {
      revalidator.revalidate();
      await refresh();
      issuesContext.refresh(undefined);
      goBack();
    },
    [revalidator, refresh, issuesContext, goBack]
  );

  return (
    <Modal headline="Pay Now" onClose={goBack}>
      <Form methods={methods} onSubmit={onSubmit}>
        <ModalContent>
          <div className="text-gray dark:text-neutral-600">
            We weren't able to collect all of your invoices yet. Please make sure your payment method is correct
            and you have sufficient funds.
          </div>
          <ModalButtonsSection>
            <Button text="Check payment method" className="button button-secondary" onClick={() => openChargebeePortal("PAYMENT_SOURCES")} size="MD" />
            {/* TODO replace with <SubmitButton/> */}
            <Button text="Pay now" className="button button-primary" disabled={payNowLinkOpened} type="submit" icon={{ Icon: FormSubmittingSpinner }} onClick={() => { }} size="MD" />
            <Button text="Done" className="button button-secondary" onClick={onDone} size="MD" />
          </ModalButtonsSection>
        </ModalContent>
      </Form>
    </Modal>
  );
}


export function BookSalesCallModal() {
  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });

  return (
    <>
      <Modal headline="Book a Sales Call for 30 Extra Trial Days" onClose={goBack}>
        <BookSalesCallModalContent />
      </Modal>
    </>
  );
}

export function DeleteAccountModal() {
  const methods = useForm();
  const goBack = useCloseModal({ blockNavigationIf: methods.formState.isSubmitting });
  const navigate = useNavigate();

  const onSubmit = useCallback(async () => {
    try {
      const response = await scraperApi.auth.requestDeletion();

      let toasterMessage = "You sent a request to our support team to delete your account. Our team will send you a confirmation email, when your account was deleted.";

      if (response === "deleted_permanently") {
        toasterMessage = "Your request to delete your account was successful. In a few seconds you will be logged out and can not log in anymore.";
        setTimeout(async () => {
          await scraperApi.auth.logout();
          navigate("/login");
        }, 6000);
      }

      Toaster.success("Account Deletion Requested", toasterMessage);
    } catch (error) {
      Toaster.error("Requesting Account Deletion Failed", "There was an issue with your request. Please try again later or contact our support team.");
    }

    goBack();
  }, [goBack, navigate]);

  return (
    <Modal headline="Request Account Deletion" onClose={goBack}>
      <Form onSubmit={onSubmit} methods={methods}>
        <ModalContent>
          <ModalSections>
            <div>Are you sure you want to delete your account?</div>
            <div>
              By deleting your account you won't be able to login anymore
              and your API key will be invalid.
            </div>
          </ModalSections>
          <ModalButtonsSection>
            <Button text="Keep Account" className="button button-secondary" onClick={goBack} size="MD" />
            {/* TODO replace with <SubmitButton /> */}
            <Button text="Request account deletion" className="button button-primary destructive" type="submit" size="MD" icon={{ Icon: FormSubmittingSpinner }} onClick={() => { }} />
          </ModalButtonsSection>
        </ModalContent>
      </Form>
    </Modal>
  );
}
