export const apiExampleResult = "<!-- Example result -->\n" +
  "\n" +
  "<!doctype html>\n" +
  "<html lang=\"en-US\">\n" +
  "<head>\n" +
  "    <link rel=\"canonical\" href=\"https://httpbin.org/json/\" />\n" +
  "    <meta property=\"og:locale\" content=\"en_US\" />\n" +
  "    <meta property=\"og:type\" content=\"website\" />\n" +
  "    <meta property=\"og:title\" content=\"saas.group - A great new home for your SaaS business\" />\n" +
  "    <meta property=\"og:description\" content=\"saas.group acquires bootstrapped SaaS companies. Built by founders and product enthusiasts. Looking for an exit? Get in touch!\" />\n" +
  "    <meta property=\"og:url\" content=\"https://saas.group/\" />\n" +
  "    <meta property=\"og:site_name\" content=\"saas.group\" />\n" +
  "    <meta property=\"article:modified_time\" content=\"2024-06-11T10:21:35+00:00\" />\n" +
  "    <meta property=\"og:image\" content=\"https://saas.group/wp-content/uploads/saas-group-ogimage-default.jpg\" />\n" +
  "    <meta property=\"og:image:width\" content=\"1280\" />\n" +
  "    <meta property=\"og:image:height\" content=\"720\" />\n" +
  "    <meta property=\"og:image:type\" content=\"image/jpeg\" />\n" +
  "    <meta name=\"twitter:card\" content=\"summary_large_image\" />\n" +
  "    <meta name=\"twitter:site\" content=\"@SaaS_group\" />\n" +
  "    <script type=\"application/ld+json\" class=\"yoast-schema-graph\">{\"@context\":\"https://schema.org\",\"@graph\":[{\"@type\":\"WebPage\",\"@id\":\"https://saas.group/\",\"url\":\"https://saas.group/\",\"name\":\"saas.group - A great new home for your SaaS business\",\"isPartOf\":{\"@id\":\"https://saas.group/#website\"},\"about\":{\"@id\":\"https://saas.group/#organization\"},\"primaryImageOfPage\":{\"@id\":\"https://saas.group/#primaryimage\"},\"image\":{\"@id\":\"https://saas.group\n";
